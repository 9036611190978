(function($) {
    $window = $(window);
    $body = $('body');
    if ($body.hasClass('page-videos-novo')) {

        var filterForm = $("#views-exposed-form-videos-novo-block"),
            filterSelect = $("#edit-tid", filterForm);
        //filterSelect.change(function() {
        //  filterForm.submit();
        //});
        $(".page-videos-novo #main-content .view-videos-novo .view-content .item-list ul li .views-field-field-areas .field-content a").click(function(e) {
            e.preventDefault();
        });

    }

        // Envolve as tabelas em uma div de classe table-responsive
        $('.field-type-text-long table').each(function () {
            if ( $(this).parent().hasClass('table-responsive') == false ) {
            $(this).wrap('<div class="table-responsive"></div>');
            }
        });
        
    /* JS livros  */
    if ($body.hasClass('page-livros')) {

        console.log("link a ser clonado");
        console.log($(".page-livros #main-content-header .more-link"));

        console.log("clonar aqui");
        console.log($("#block-bean-livrarias-livro .block-title"));

        var morelink = $("#main-content header .more-link"),
            titleLivraria = $("#block-bean-livrarias-livro .block-title");
        $(morelink).clone().insertAfter(titleLivraria);

    }


    //Tradução placeholder busca acervo acadêmico
    if (document.documentElement.lang == 'en') {
        $('#block-block-1 #uquery').attr('placeholder', 'Production and academic archive');
    }

    if ($("#edit-ano-value-year").val() == "") {
        $('#edit-data-value-month').attr('disabled', 'disabled');
    }

    //  buildVideoPopup();
    $('#views-exposed-form-busca-query-page input[type="checkbox"]').click(function() {
        $('form#views-exposed-form-busca-query-page').submit();
    });

    if ($('.view-mosaicos .view-content').length > 0) {
        var $grid = $('.view-mosaicos .view-content').addClass('masonry-container').imagesLoaded(function() {
            setTimeout(function() {
                $grid.masonry();
            }, 500);
        });
    }

    if ($('.view-posts-redes-sociais .view-content').length > 0) {
        var $grid2 = $('.view-posts-redes-sociais .view-content').addClass('masonry-container').imagesLoaded(function() {
            setTimeout(function() {
                $grid2.masonry();
            }, 500);
        });
    }

    $(".abrir-mapa").click(function() {
        $(this).toggleClass("active").next().slideToggle();
    });

    $(".container-mapa").each(function() {
        if ($(this).children().length < 1) {
            $(this).prev().hide();
        }
    });

    $(".form-item-submitted-areas-todas .form-checkbox").attr("checked", true);
    //
    // Mantém o ano do copyright atualizado
    // 

    $('.copyright .ano').text(new Date().getFullYear());
    //
    // Página Fale Conosco
    //
    $(".page-livros #main-content .more-link a").attr("href", "https://editora.fgv.br").attr("target", "_blank");

    if (($body.hasClass('page-node-22')) || ($body.hasClass('page-node-975'))) {

        //
        // Mapa
        //
        var marker, i;
        var locations = [];
        var map = new google.maps.Map(document.getElementById('enderecos-map'), {
            center: new google.maps.LatLng(-22.9414491, -43.1799295),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            zoom: 5
        });

        $('.view-enderecos .view-grouping-content .item-list:nth-child(2) .views-row:first-child').addClass('active');
        $('.view-enderecos .views-row').each(function() {
            var endereco = $(this).find('.views-field-title .field-content');
            var helper = [];
            helper.push(endereco.data('title'), endereco.data('lat'), endereco.data('lon'));
            locations.push(helper);
            $(this).find('.views-field-title').on('click', function() {
                $('.view-enderecos .views-row').removeClass('active');
                $(this).parent().addClass('active');
                map.setCenter(new google.maps.LatLng(endereco.data('lat'), endereco.data('lon')));
                map.setZoom(17);
            });
        });

        count = locations.length;
        for (i = 0; i < count; i++) {
            marker = new google.maps.Marker({
                icon: '//' + document.domain + '/sites/portal.fgv.br/themes/portalfgv/images/map-marker.png',
                map: map,
                position: new google.maps.LatLng(locations[i][1], locations[i][2])
            });
        }

        // Seletor de cidades

        $('.cidade-select:first-child').addClass('active');
        $('.cidade-select').on('click', function() {
            if (!$(this).hasClass('active')) {
                //var childNum = $(this).index();
                $('.cidade-select').removeClass('active');
                $(this).addClass('active');
                $(this).parent().next().children().hide();
                if ($(this).hasClass('rj')) {
                    $(this).parent().next().children().eq(1).show();
                } else if ($(this).hasClass('sp')) {
                    $(this).parent().next().children().eq(2).show();
                } else {
                    $(this).parent().next().children().eq(0).show();
                }
            }
        });

        // Ver unidades
        $('.views-field-ver-unidades').on('click', function() {
            $(this).toggleClass('active').next().find('.item-list').slideToggle();
        });
    }

    //
    // Abrir janela menor nos botões de compartilhar dos conteúdos
    // 
    //if ($('.field-name-field-compartilhar .share:not(.whatsapp) a').length > 0) {
    $('.field-name-field-compartilhar .share:not(.whatsapp) a').on('click', function() {
        window.open($(this).attr('href'), 'Compartilhe', 'width=500, height=400');
        return false;
    });
    //}

    //
    //PERIODICOS
    //

    $(".field-name-field-periodico-categoria").click(function() {
        if (theLanguage == "en") {
            peris = " Periodicals";
            peri = " Periodical";
        } else {
            peris = " Periódicos";
            peri = " Periódico"
        }
        count = $(this).parents('.entity-paragraphs-item').find('.field-name-field-periodico-url .field-items > .field-item').length;
        if ($(this).parents('.entity-paragraphs-item').find('.field-name-field-periodico-url .field-items > .field-item').length == 1) {
            countp = count + peri;
        }
        if ($(this).parents('.entity-paragraphs-item').find('.field-name-field-periodico-url .field-items > .field-item').length == 0) {
            return;
        }
        if ($(this).parents('.entity-paragraphs-item').find('.field-name-field-periodico-url .field-items > .field-item').length > 1) {
            countp = count + peris;
        }
        $(this).next().fadeIn();
        $(this).next().prepend('<span class="texto-peri">' + countp + '</span>');
        $(this).next().prepend('<h2 class="titulo-peri">' + $(this).find('.field-item').text() + '</h2>');
        $(this).next().append("<div class='close-button'></div>");
        $('body').after("<div class='overlay-black'></div>");
        $('.overlay-black, .close-button,.botao-azul').click(function() {
            $('.field-name-field-periodico-categoria').next().fadeOut('fast');
            $('.overlay-black,.close-button,.titulo-peri,.texto-peri').remove();
        });
    });

    var $playerBanner = $('<div id="playerBanner"><div class="exit"/><div class="container"><div class="videoWrapper"><div class="botao-fechar"/></div></div></div>');

    $playerBanner.hide();
    $playerBanner.find('.exit, .botao-fechar').click(function(e) {
        $playerBanner.fadeOut('slow', function() {
            $playerBanner.find('iframe').remove();
        });
    });

    $('body').append($playerBanner);

    $(".abrir-video").once('abrir-video-popup').click(function(e) {
        e.preventDefault();

        $playerBanner = $('#playerBanner');
        $playerBanner.find('.botao-fechar').hide();

        var url = $(this).attr('href');
        $iframe = $('<iframe id="youtube-banner-video" width="560" height="500px" src="' + url + '" frameborder="0" allowfullscreen></iframe>');
        $playerBanner.find('iframe').remove();
        $playerBanner.find('.videoWrapper').append($iframe);
        $playerBanner.fadeIn('slow', function() {
            setTimeout(function() {
                $playerBanner.find('.botao-fechar').fadeIn('slow');
                var video = document.getElementById('youtube-banner-video');
                if (typeof addVideoToQueue === 'function') {
                    addVideoToQueue(video);
                }
            }, 500);
        });
    });

    $(".banner-principal-home .bg-videos li").each(function() {
        $src = $(this).find('.views-field-field-banner-imagem img').attr('src');
        $link = $(this).find('.views-field-field-banner-imagem a').attr('href');
        if ($src == undefined || $src == '') {
            $src = $(this).find('.views-field-field-banner-imagem img').attr('data-src');
        }

        if ($src !== undefined && $src !== '') {
            $(this).css('background', 'url("' + $src + '")no-repeat center center / cover');
            if ($link !== undefined && $link !== '') {
                $(this).prepend('<a class="linkImgBanner" href=' + $link + '></a>');
            }
        }
    });

    var button_pesq = $(".views-field-field-banner-link-abaixo");
    var button_pesq_a = button_pesq.find("a").attr("href");
    var button_pesq_text = button_pesq.find("a").text();
    if (button_pesq.length > 0) {
        $("#block-views-banner-video-block ul li .views-field-field-banner-video a").css("height", "90%");
        $playerBanner = $('<div id="playerBanner"><div class="exit"/><div class="container"><div class="videoWrapper"><div class="botao-fechar"/><div class="botao-pesq"><a class="btn-azul-a" href=' + button_pesq_a + '>' + button_pesq_text + '</a></div></div></div>');
    } else {
        $playerBanner = $('<div id="playerBanner"><div class="exit"/><div class="container"><div class="videoWrapper"><div class="botao-fechar"/></div></div></div>');
    }
    $overlayw = $('<div class="overlay-white"></div>');
    $playerBanner.hide();
    $playerBanner.find('.exit, .botao-fechar').click(function(e) {
        $playerBanner.fadeOut('slow', function() {
            $playerBanner.find('iframe').remove();
        });
    });
    $('body').remove('#playerBanner').append($playerBanner);

    $bgVideo = $('.block-banner .bg-player');

    if ($bgVideo.length == 1) {
        var cookieName = 'portal_fgv_video';
        var cookieVideo = $.cookie(cookieName);

        if (cookieVideo != null || cookieVideo != 'undefined') {
            cookieVideo = JSON.parse(cookieVideo);
        }

        if (((cookieVideo == null || cookieVideo == 'undefined') || (cookieVideo.path == '/' && document.location.pathname != '/')) || ($bgVideo.data('video-url') != cookieVideo.video)) {
            var cValue = { path: document.location.pathname, video: $bgVideo.data('video-url') };
            $.cookie(cookieName, JSON.stringify(cValue), { expires: 30, path: document.location.pathname });

            $bgVideo.parent().find('.abrir-video').remove();

            $bgVideo.parents('.bg-videos').addClass('bg-video-executando');

            $bgVideo.parents('.views-row').find('.views-field-field-banner-imagem img').each(function() {
                var $img = $(this);
                $img.animate({
                    opacity: 0
                }, 500, function() {
                    $img.css('visibility', 'hidden');

                    $src = $(this).attr('src');
                    if ($src == undefined || $src == '') {
                        $src = $(this).attr('data-src');
                    }

                    if ($src !== undefined && $src !== '') {
                        $bgVideo.parents('.bg-videos').css('background', 'url("' + $src + '")no-repeat center center / cover');
                    }
                });
            });

            $bgVideo.mb_YTPlayer();
            $('.views-field-field-banner-video').click(function(e) {
                e.preventDefault();
                $(".block-banner .bg-player").YTPTogglePlay(changeClass);
            });
            /*
             * Muda o estado dos vídeos em background play / pause
             */
            function changeClass(state) {
                if (state != 1) {
                    $('.views-field-field-banner-video').addClass("paused");
                } else {
                    $('.views-field-field-banner-video').removeClass("paused");
                }
            }
        } else {
            $bgVideo.remove();
        }
    }

    $window.load(function() {
        if ($(this).width() > 1200) {
            $("body").addClass("desktop");
        }
        if ($(this).width() < 1200 && $(this).width() > 990) {
            $("body").addClass("tablet");
        }
        if ($(this).width() < 990) {
            $("body").addClass("mobile");
        }

        //Scroll pro footer
        if (window.location.search === '?to=footer') {
            $('html, body').animate({ scrollTop: $(document).height() }, 100);
        }
    });

    $window.resize(function() {
        if ($(this).width() > 1200 && ($("body").hasClass("mobile") || $("body").hasClass("tablet"))) {
            window.location.href = window.location.href;
            $("body").removeClass("mobile").removeClass("tablet").addClass("desktop");
        }
        if ($(this).width() < 1200 && $(this).width() > 990 && ($("body").hasClass("mobile") || $("body").hasClass("desktop"))) {
            window.location.href = window.location.href;
            $("body").removeClass("mobile").removeClass("desktop").addClass("tablet");
        }
        if ($(this).width() < 990 && ($("body").hasClass("tablet") || $("body").hasClass("desktop"))) {
            window.location.href = window.location.href;
            $("body").removeClass("tablet").removeClass("desktop").addClass("mobile");
        }
    });

    //
    // FAQ
    //
    $(".estilo-faq .view-content li").click(function() {
        $(this).toggleClass("ativo").children(".views-field-field-resposta").slideToggle();
    });

    //
    // PARCERIA
    //        
    $('.parceiro-accordion .view-content h3').click(function() {
        $(this).next('ul').slideToggle();
    });

    var theLanguage = $('html').attr('lang');

    //
    // INSTITUCIONAL ESTRUTURA
    //

    $(".field-name-field-botoes-computed a").click(function(e) {
        e.preventDefault();
        var link = $(this).data('link');
        var titleHeight = $('.quicktabs-escura h2.block-title').height();
        // $(link).click();
        // var lastChar = link.substr(link.length - 1);
        // if ($window.width() < 990) {
        //     swiperInstitucional.slideTo(lastChar);
        // }
        $('.quicktabs-escura.popup').fadeIn();
        $('.quicktabs-escura.popup .block-title').append("<div class='close-button'></div>");
        $('body').after("<div class='overlay-black'></div>");
        $('html, body').animate({ scrollTop: $(link).offset().top + titleHeight - 120 }, 1000);
        $(link).click();
        $('.overlay-black, .close-button').click(function() {
            $('.quicktabs-escura.popup').fadeOut('fast');
            $('.overlay-black').remove();
        });
    });

    // $(".field-name-field-botoes-computed a").click(function (e) {
    //     e.preventDefault();
    //     $('.quicktabs-escura').modal();
    // });

    /*============================================================================================================
     * SWIPERS
     =============================================================================================================*/
    if ($(".autores-think > .view-content > .item-list > ul.swiper-wrapper > li").length > 1) {
        $('.autores-think .view-content > .item-list > ul').after($('<div class="num-pager"><div class="swiper-pagination"></div></div><div class="swiper-button-prev"></div><div class="swiper-button-next"></div>'));
        var swiper = new Swiper('.autores-think .view-content > .item-list', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            slidesPerView: '1',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
        });
    }
    $('.view-banner-livro .view-content > .item-list > ul').after($('<div class="num-pager"><div class="swiper-pagination"></div></div>'));
    var swiper = new Swiper('.view-banner-livro .view-content > .item-list', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        slidesPerView: '1'
    });

    //
    // Swiper de indicadores 
    //
    var indices = new Swiper('.ticker', {
        autoplay: 5000,
        autoplayDisableOnInteraction: false,
        loop: true,
        slidesPerView: 1,
        threshold: 25
    });

    //
    // Banner Principal Swiper
    //
    $('.banner-content').each(function() {
        if ($(this).find('.views-row').length > 1) {
            if ($(this).hasClass('home')) {
                var banner = new Swiper('.banner-content', {
                    autoplay: 5000,
                    slidesPerView: 1,
                    threshold: 25,
                    pagination: '.banner-pagination',
                    paginationClickable: true,
                    nextButton: '.banner-pagination-next',
                    prevButton: '.banner-pagination-prev'
                });
            } else {
                var banner = new Swiper('.banner-content', {
                    autoplay: 5000,
                    slidesPerView: 1,
                    threshold: 25,
                    pagination: '.banner-pagination',
                    paginationClickable: true,

                    //#01 CODIGO PAGINADOR ANTIGO PORTAL COM IMAGEM E TEXTO 
                    onSlideChangeStart: function(banner) {
                        pager.slideTo(banner.activeIndex);
                        $('.banner-pager li').removeClass('pager-active');
                        $('.banner-pager li').eq(banner.activeIndex).addClass('pager-active');
                    }
                });
            }
            var banner = new Swiper('.banner-content', {
                autoplay: 5000,
                slidesPerView: 1,
                threshold: 25,
                pagination: '.banner-pagination',
                paginationClickable: true,
                nextButton: '.banner-pagination-next',
                prevButton: '.banner-pagination-prev',

                //#01 CODIGO PAGINADOR ANTIGO PORTAL COM IMAGEM E TEXTO 
                //                onSlideChangeStart: function (banner) {
                //                    pager.slideTo(banner.activeIndex);
                //                    $('.banner-pager li').removeClass('pager-active');
                //                    $('.banner-pager li').eq(banner.activeIndex).addClass('pager-active');
                //                }
            });
            //            #02 CODIGO PAGINADOR ANTIGO PORTAL COM IMAGEM E TEXTO 
            if ($('.banner-pager .views-row').length > 1) {
                var $bannerPager = $('.banner-pager');
                var pager = new Swiper('.banner-pager', {
                    slidesPerView: 4,
                    threshold: 25,
                    onInit: function(pager) {
                        $('.banner-pager li.swiper-slide-active').addClass('pager-active');
                        $('.banner-pager li').on('click', function() {
                            banner.slideTo($(this).index());
                            $('.banner-pager li').removeClass('pager-active');
                            $(this).addClass('pager-active');
                        });
                        if ($('.banner-pager li').length < 4) {
                            $('.banner-pager .swiper-wrapper').css('justify-content', 'center');
                        }
                    }
                });

                $bannerPager.css('visibility', 'visible').animate({ opacity: 1 });
            }
        }
    });


    //
    // Depoimentos Swiper
    // 
    var depoimentos = new Swiper('.depoimentos-content', {
        autoplay: 5000,
        pagination: '.depoimentos-pager',
        paginationClickable: true,
        slidesPerView: 1,
        threshold: 25
    });
    //
    //Artigos Swiper
    //

    var artigos = new Swiper('.artigos-list', {
        autoplay: 0,
        nextButton: '.artigo-pagination-next',
        prevButton: '.artigo-pagination-prev',
        slidesPerView: 3,
        breakpoints: {
            // when window width is <= 320px
            990: {
                slidesPerView: 1,
                pagination: '.artigos-pagination',
                paginationClickable: true,
                autoHeight: true
            }
        }
    });
    //
    //Noticias Swiper
    //

    var noticias = new Swiper('.noticias-home-list', {
        autoplay: 5000,
        pagination: '.noticia-pagination',
        paginationClickable: true,
        slidesPerColumn: 2,
        threshold: 25,
        breakpoints: {
            // when window width is <= 320px
            990: {
                slidesPerColumn: 1
            }
        }
    });
    if ($(".view-banner-principal .attachment .swiper-slide").length === 1) {
        $(".view-banner-principal .attachment").css("display", "none");
    }
    /*============================================================================================================
     * SWIPERS MOBILE
     =============================================================================================================*/
    if ($window.width() < 990) {
        $(document).bind('cbox_complete', function() {
            var button_pesq = $(".views-field-field-banner-link-abaixo");
            var button_pesq_a = button_pesq.find("a").attr("href");
            var button_pesq_text = button_pesq.find("a").text();
            if (button_pesq.length > 0) {
                $("#cboxContent").append('<div class="botao-pesq"><a class="btn-azul-a" href=' + button_pesq_a + '>' + button_pesq_text + '</a></div>');
            }
        });
        $(document).bind('cbox_closed', function() {
            $(".botao-pesq").remove();
        });

        //MENU AREAS
        $("#quicktabs-view__quicktabs_dci__block ul li a").click(function() {
            $("#quicktabs-container-view__quicktabs_dci__block").stop(true, true).animate({ right: "+=100%" });
            $("#quicktabs-container-view__quicktabs_dci__block").prepend("<div class='button-back1'></div>");
            $("html").addClass("overflow");
            $(".button-back1").click(function() {
                $("#quicktabs-container-view__quicktabs_dci__block").stop(true, true).animate({ right: "-=100%" });
                $(".button-back1").remove();
                $("html").removeClass("overflow");
            });
        });
        $('.yui-content form:not(.form-ebs2) input.form-text').attr('placeholder', 'Busca');
        //
        //ESCOLAS
        //
        //
        $('.swiper-escola .view-content .item-list').addClass('swiper-slide');
        $('.swiper-escola .view-content').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="swiper-pagination"></div></div>'));
        var swiper = new Swiper('.esc-swiper', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            slidesPerView: '1'
        });

        $('.swiper-areas .view-display-id-attachment_4 .view-content .item-list').addClass('swiper-slide');
        $('.swiper-areas .view-display-id-attachment_4 .view-content').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="swiper-pagination"></div></div>'));
        var swiper = new Swiper('.swiper-areas .view-display-id-attachment_4', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            slidesPerView: '1'
        });



        // var swiperInstitucional = new Swiper('.view-estrutura-organizacional .quicktabs-tabs', {
        //     pagination: '.swiper-pagination',
        //     paginationClickable: true,
        //     centeredSlides: true,
        //     slidesPerView: '1'
        // });

        //
        //FGV NO MUNDO
        //

        $('.swiper-auto-1 .view-content .item-list > ul > li').addClass('swiper-slide');
        $('.swiper-auto-1 .view-content .item-list > ul').addClass('swiper-wrapper');
        var swiper = new Swiper('.swiper-auto-1 .view-content .item-list', {
            paginationClickable: true,
            slidesPerView: 'auto'
        });

        $('.swiper-auto-0 .view-content .item-list > ul > li').addClass('swiper-slide');
        $('.swiper-auto-0 .view-content .item-list > ul').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="swiper-pagination"></div></div>'));
        var swiper = new Swiper('.swiper-auto-0 .view-content .item-list', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            slidesPerView: '1',
            autoHeight: false
        });
        //
        //PREMIOS BLOCK
        //

        $('.swiper-paragraph-one .paragraphs-items > .field-type-paragraphs > .field-items > .field-item').addClass('swiper-slide');
        $('.swiper-paragraph-one .paragraphs-items > .field-type-paragraphs > .field-items').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="swiper-pagination"></div></div>'));
        var swiper = new Swiper('.swiper-paragraph-one .paragraphs-items > .field-type-paragraphs', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            slidesPerView: '1'
        });

        //
        //SWIPER PARAGRAPHS
        //

        $('.swiper-paragraph .paragraphs-items > .field-type-paragraphs > .field-items > .field-item').addClass('swiper-slide');
        $('.swiper-paragraph .paragraphs-items > .field-type-paragraphs > .field-items').addClass('swiper-wrapper');
        var swiper = new Swiper('.swiper-paragraph .paragraphs-items > .field-type-paragraphs', {
            paginationClickable: true,
            slidesPerView: 'auto'
        });

        // $('#quicktabs-international_students .quicktabs-tabs li a').click(function(){
        //     swiperReconhecimento.update()
        // });

        //
        //FGV QUICKTABS INSTITUCIONAL
        //

        function geSlideDataIndex(swipe) {
            var activeIndex = swipe.activeIndex;
            var slidesLen = swipe.slides.length;
            if (swipe.params.loop) {
                switch (swipe.activeIndex) {
                    case 0:
                        activeIndex = slidesLen - 3;
                        break;
                    case slidesLen - 1:
                        activeIndex = 0;
                        break;
                    default:
                        --activeIndex;
                }
            }
            return activeIndex;
        }
        $('.swiper-quick .quicktabs-wrapper .item-list ul li').addClass('swiper-slide');
        $('.swiper-quick .quicktabs-wrapper .item-list ul').addClass('swiper-wrapper');

        var swiperInstitucional = new Swiper('.swiper-quick .quicktabs-wrapper .item-list', {
            slidesPerView: 'auto',
            centeredSlides: true,
            breakpoints: {
                310: {
                    slidesPerView: 1,
                },
                480: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 3,
                }
            },
            onInit: function() {
                $('.field-name-field-botoes-computed a').click(function(e) {
                    e.preventDefault();
                    var link = $(this).data('link');
                    $(link).click();
                    var lastChar = link.substr(link.length - 1);
                    swiperInstitucional.slideTo(lastChar);
                });
            },
            onSlideChangeEnd: function(swipe) {
                var swiperLanguage;
                var url = $(location).attr('href');
                //var url = window.location.href;
                var pathArray = url.split('/').reverse()[1];
                if (pathArray === 'en') {
                    swiperLanguage = 'en';
                } else if (pathArray === 'cn') {
                    swiperLanguage = 'zh_hans';
                } else {
                    swiperLanguage = 'pt_br';
                }
                $('#quicktabs-tab-view__estrutura_organizacional__block_' + swiperLanguage + '-' + geSlideDataIndex(swipe)).click();
            }
        });

        // $(".field-name-field-botoes-computed a").click(function (e) {
        //     console.log($(this));
        //     e.preventDefault();
        //     var link = $(this).data('link');
        //     var titleHeight = $('.quicktabs-escura h2.block-title').height();
        //     $(link).click();
        //     var lastChar = link.substr(link.length - 1);
        //     if ($window.width() < 990) {
        //         swiperInstitucional.slideTo(lastChar);
        //     }
        // });


        //
        //THINK TANK BUSCA
        //
        $('#block-block-7 .yui-content').addClass('swiper-wrapper').after($('<div class="swiper-button swiper-button-next"></div><div class="swiper-button swiper-button-prev"></div>'));
        $('#block-block-7 .block-content .yui-wrapper > ul > li').addClass('swiper-slide');
        $('#block-block-7 .block-content ul').addClass('swiper-wrapper');
        var pager = new Swiper('#block-block-7 .yui-wrapper', {
            slidesPerView: 1,
            threshold: 25,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
        });

        $('#block-block-7 .yui-content > div').addClass('swiper-slide').addClass('swiper-no-swiping');
        var think = new Swiper('#block-block-7 #demo', {
            slidesPerView: 1,
            threshold: 25,
            autoHeight: true,
            allowSwipeToNext: false,
            allowSwipeToPrev: false
        });

        pager.params.control = think;

        $('.estilo-swiper3 .view-header').each(function() {
            $(this).insertAfter($(this).siblings('.view-content'));
        });

        $("#block-bean-pagina-avaliacaoitens .field-name-field-avaliacaopage-nome,#block-bean-pagina-avaliacaoitens-replicated .field-name-field-avaliacaopage-nome").click(function() {
            $(this).next().slideToggle();
            $(this).toggleClass("ativo");
        });
    }

    //
    // Mapa página institucional
    //

    if (
        $body.hasClass('node-type-relatorio-anual') || $body.hasClass('page-institucional') && ($window.width() > 990)
    ) {
        var marker, i, position;
        var countries = [];
        var pos = [];
        var map = new google.maps.Map(document.getElementById('fgv-map'), {
            center: new google.maps.LatLng(0, 15),
            disableDoubleClickZoom: true,
            draggable: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            minZoom: 2,
            scrollwheel: false,
            streetViewControl: false,
            zoom: 2,
            zoomControl: false
        });

        var url = '//' + document.domain + '/json/paises/';
        if (document.documentElement.lang == 'zh-hans') {
            url = '//' + document.domain + '/cn/json/paises/';
        } else if (document.documentElement.lang !== 'pt-br') {
            url = '//' + document.domain + '/' + document.documentElement.lang + '/json/paises/';
        }

        function createTooltip(marker, partners) {
            //create a tooltip
            var tooltipOptions = {
                marker: marker,
                content: '<span class="pais">' + marker.title + '</span><span class="parceiros">' + partners + '</span>',
                cssClass: 'map-tooltip', // name of a css class to apply to tooltip,
                position: marker.position
            };
            var tooltip = new Tooltip(tooltipOptions);
        }

        function contentFill(country, numPartners, partners) {
            $('#partners').html(
                '<div class="content">' +
                '<h3>' + country + '</h3>' +
                '<span>' + numPartners + '</span>' +
                '<ul>' + partners + '</ul>' +
                '<span class="close">X</span>' +
                '</div>'
            );
        }

        $.ajax({
            type: 'GET',
            url: url,
            timeout: 45000,
            contentType: 'application/json',
            dataType: 'json',
            success: function(data) {
                data = JSON.stringify(data);
                data = JSON.parse(data);
                //console.log(data);
                for (var i = 0; i < data.length; i++) {
                    marker = new google.maps.Marker({
                        icon: '//' + document.domain + '/sites/portal.fgv.br/themes/portalfgv/images/map-marker-ins.png',
                        map: map,
                        position: new google.maps.LatLng(data[i].pais.Latitude, data[i].pais.Longitude),
                        title: data[i].pais.name,
                        countryId: data[i].pais.id,
                        partNumber: data[i].pais.parceiros
                    });
                    createTooltip(marker, marker.partNumber);
                    //console.log(marker.partNumber);
                    marker.addListener('click', function(e) {
                        var item = this;
                        ajaxmap(this.countryId, this);
                    });
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {}
        });
    }

    $(".radioactivity-process").click(function() {
        $.get($(this).attr('rel'));
    });

    $(".titulo-mapa").click(function(e) {
        e.preventDefault();
        ajaxmap($(this).attr('data-id'), null, $(this).attr('data-title'), $(this).attr('data-numberp'));
        $('html').css('overflow', 'hidden');
    });

    /*============================================================================================================
     * FORMULÁRIO RODAPÉ
     =============================================================================================================*/
    if ($('#block-block-31').length > 0) {
        $('#block-block-31').append('<div class="close-button"></div>');
        $('body').after("<div class='overlay-black'></div>");
        $('.overlay-black, .close-button,.botao-azul').click(function(e) {
            $('#block-block-31').fadeOut('fast');
            $('.overlay-black').remove();
        });
    }

    var showNewsletter = function() {
        $('.newsletter').fadeIn();
        $('.newsletter .block-title').append('<div class="close-button"></div>');
        $('body').after('<div class="overlay-black"></div>');
        $('.overlay-black, .close-button,.botao-azul').click(function(e) {
            $('.newsletter').fadeOut('fast');
            $('#webform-client-form-14 .webform-component--email, #webform-client-form-9841 .webform-component--email').hide();
            $('.overlay-black').remove();
        });
    }

    $('#block-block-11 input.submit-news').click(function(event) {
        event.preventDefault();
        var email = $('#block-block-11 input.email-news').val();
        if (email.length > 0 && validaemail(email)) {
            $('#edit-submitted-email').val($('#block-block-11 input.email-news').val());
            showNewsletter();
        }
    });

    if (getParameterByName('SubscribeForm') == 'Open') {
        $('#webform-client-form-14 .webform-component--email, #webform-client-form-9841 .webform-component--email').show();
        showNewsletter();
    }

    // Pagina International Students
    $('#block-bean-international-students-botao-c .field-type-link-field a').click(function(event) {
        event.preventDefault();
        $('.block-contact-us').fadeIn();
        $('.block-contact-us .block-title').append('<div class="close-button"></div>');
        $('body').after('<div class="overlay-black"></div>');
        $('.overlay-black, .close-button,.botao-azul').click(function(e) {
            $('.block-contact-us').fadeOut('fast');
            //$('#webform-client-form-14 .webform-component--email, #webform-client-form-9841 .webform-component--email').hide();
            $('.overlay-black').remove();
        });
    });


    $(".button-mailing").click(function(event) {
        event.preventDefault();
        $('#block-webform-client-block-169').fadeIn();
        $('#block-webform-client-block-169').append("<div class='close-button'></div>")
        $('body').after("<div class='overlay-black'></div>");
        $('.overlay-black, .close-button').click(function() {
            $('#block-webform-client-block-169').fadeOut('fast');
            $('.overlay-black').remove();
        });
    });

    $(".page-user-me #header-wrapper").addClass("white");
    if ($(".banner-home").length > 0 || $("#block-views-banner-principal-block-1").length > 0 || $("#block-views-banner-paginas-block").length > 0 || $('#block-views-custom-banner-block').length > 0) {
        $("#header-wrapper").removeClass("white").addClass("black");
    } else {
        $("#header-wrapper").addClass("white").removeClass("black");
    }

    $('.map-container').click(function() {
            $(this).find('iframe').addClass('clicked')
        })
        .mouseleave(function() {
            $(this).find('iframe').removeClass('clicked')
        });

    function ajaxmap(id, element, datatitle, datanumber) {
        $('#block-block-26 #partners').fadeIn('fast', function() {
            $('#block-block-26 #partners').html('<div class="loading"></div>');
        });
        $('#page-wrapper').append('<div class="overlay"></div>');
        $.ajax({
            type: "GET",
            url: '//' + document.domain + '/json/instituicoes/' + id,
            timeout: 45000,
            contentType: "application/json",
            dataType: "json",
            success: function(result) {
                result = JSON.stringify(result);
                result = JSON.parse(result);
                var parceiros = '';
                for (var i = 0; i < result.length; i++) {
                    parceiros += '<li><a target="_blank" href="' + result[i].instituicao.url + '">' + result[i].instituicao.title + '</a></li>';
                }
                if (element !== null) {
                    contentFill(element.title, element.partNumber, parceiros);
                } else {
                    contentFill(datatitle, datanumber, parceiros);
                }
                $('.overlay, #partners .close').click(function() {
                    $('#block-block-26 #partners').fadeOut('fast', function() {
                        $('.overlay').remove();
                        $('html').removeAttr('style');
                        $('#partners').html('');
                    });
                });
            },
            error: function(jqXHR, textStatus, errorThrown) {}
        });
    }

    //
    // Selects formulário ouvidoria
    //

    function mostra_esconde_conveniado() {
        var inst;

        inst = $("#webform-client-form-9950 #edit-submitted-instituicao-institute option:selected").val();

        if (inst !== 'FGVMGM') {
            $("#webform-client-form-9950 #edit-submitted-conveniado-conveniado-nome option")
                .removeAttr("selected")
                .eq(0)
                .attr("selected", "selected");
            mudou_nome();
        }
    }

    if ($body.hasClass('page-node-9950')) {
        var $s = $("#webform-client-form-9950 #edit-submitted-instituicao-institute");

        if (!$s.length)
            return;

        $s.change(mostra_esconde_conveniado);
        mostra_esconde_conveniado();

        $("#webform-client-form-9950 #edit-submitted-conveniado-conveniado-cidade option")
            .eq(0)
            .text('- Selecione o nome do associado / select associate name first -')
            .end()
            .filter(':gt(0)')
            .each(function() { // Converte NOME/CIDADE => "CIDADE"
                var $t = $(this);
                var txt = $t.text();
                $t.text(txt.substr(txt.indexOf('/') + 1));
            })
            .hide();


        function mudou_nome() {
            var $t = $("#webform-client-form-9950 #edit-submitted-conveniado-conveniado-nome");
            var $cidade = $("#webform-client-form-9950 #edit-submitted-conveniado-conveniado-cidade");
            var nome = $t.find('option:selected').val();
            var sel;

            // Escolhe o seletor de option do select a mostrar
            if (nome)
                sel = 'option[value^=' + nome + ']'; // Valor comecando com nome
            else
                sel = 'option:eq(0)'; // Primeiro

            $cidade
                .find('option')
                .removeAttr('selected')
                .hide()
                .end()
                .find(sel)
                .show()
                .end()
                .find('option:visible:eq(0)').attr('selected', 'selected');
        }

        $("#webform-client-form-9950 #edit-submitted-conveniado-conveniado-nome").change(mudou_nome);

        mudou_nome();
    }

    var tabativa = "";

    /*=======================================================================================================================
     *     DRUPAL BEHAVIORS  //  DRUPAL BEHAVIORS  //  DRUPAL BEHAVIORS  //  DRUPAL BEHAVIORS  //  DRUPAL BEHAVIORS  //
     ========================================================================================================================*/
    Drupal.behaviors.portalfgv = {
        attach: function(context, settings) {
            
            // envolve o iframe em uma div de classe video-container
            $('iframe[src*="youtube.com"]').once('youtube-iframe-responsive').each(function () {
                if ( $(this).parent().hasClass('video-container') == false ) {
                    $(this).wrap('<div class="video-container"></div>');
                }
            });

            //Evita submissões multiplas em formulários detectando submits
            $('form').once('form-prevent-multi-submit', context).each(function() {
                $(this).submit(function(e) {
                    if (!e.isPropagationStopped()) {
                        $(this).find('input[type="submit"]').addClass('wait-submit').click(function(e) {
                            if ($(this).hasClass('wait-submit')) {
                                e.preventDefault();
                            }
                        });
                    }
                });
            });

            $window = $(window);
            $body = $('body');

            if ($('.newsletter .webform-confirmation').length > 0) {
                $('.newsletter .block-title').css('display', 'none');
            }
            $('.botao-azul').click(function() {
                $('.newsletter').fadeOut('fast');
                $('.overlay-black').remove();
            });
            var palavraChave = "";
            var dataNoticiaInicio = "";
            var dataNoticiaFinal = "";

            if ($("body").hasClass("i18n-pt-br")) {
                palavraChave = "Palavra-chave";
                dataNoticiaInicio = "Data inicial";
                dataNoticiaFinal = "Data final"
            } else {
                palavraChave = "Key-word";
                dataNoticiaInicio = "Initial Date";
                dataNoticiaFinal = "Final Date"
            }
            $('#edit-keys-news-wrapper input.form-text,#edit-keys-news1-wrapper input.form-text').attr('placeholder', palavraChave);
            $('#edit-date-wrapper input,#edit-date2-wrapper input').attr('placeholder', dataNoticiaInicio);
            $('#edit-date1-wrapper input,#edit-date3-wrapper input').attr('placeholder', dataNoticiaFinal);

            // Return today's date and time
            var currentTime = new Date();
            var month = currentTime.getMonth() + 1;
            var year = currentTime.getFullYear();

            $("#edit-ano-value-year").change(function() {
                $("#views-exposed-form-eventos-page .form-submit").click();
                $('#edit-data-value-month').removeAttr('disabled');

            });
            $("#edit-data-value-month").change(function() {
                if ($("#edit-data-value-month").val() !== "") {
                    $('#edit-data-value-year').val($("#edit-ano-value-year").val());
                    $("#views-exposed-form-eventos-page .form-submit").click();
                } else {
                    $('#edit-data-value-year').val("");
                }
            });

            if ($("#edit-ano-value-year").val() == year) {
                $('.view-display-id-page #edit-data-value-month option').each(function() {
                    if ($(this).val() < month && $(this).val() >= 1) {
                        $(this).css("display", "none");
                    }
                });
                $('.view-display-id-page_1 #edit-data-value-month option').each(function() {
                    if ($(this).val() > month) {
                        $(this).css("display", "none");
                    }
                });
            } else if ($("#edit-ano-value-year").val() == "") {
                $('#edit-data-value-month').val("").attr('disabled', 'disabled');
            } else {
                $('#edit-data-value-month option').css("display", "block");
            }
            /*============================================================================================================
             * SOMENTE DESKTOP
             =============================================================================================================*/

            if ($window.width() > 990) {
                //SITES FGV
                var num_colunas = 3,
                    listItem = 'li',
                    listClass = 'sub-list';
                $('.split-list', context).once('lista-ordenada').each(function() {
                    var itens_por_coluna = new Array(),
                        items = $(this).find(listItem),
                        min_items_per_col = Math.floor(items.length / num_colunas),
                        difference = items.length - (min_items_per_col * num_colunas);
                    for (var i = 0; i < num_colunas; i++) {
                        if (i < difference) {
                            itens_por_coluna[i] = min_items_per_col + 1;
                        } else {
                            itens_por_coluna[i] = min_items_per_col;
                        }
                    }
                    for (var i = 0; i < num_colunas; i++) {
                        $(this).append($('<ul ></ul>').addClass(listClass));
                        for (var j = 0; j < itens_por_coluna[i]; j++) {
                            var pointer = 0;
                            for (var k = 0; k < i; k++) {
                                pointer += itens_por_coluna[k];
                            }
                            $(this).find('.' + listClass).last().append(items[j + pointer]);
                        }
                    }
                });
                var $containermaior1 = $('.split-list .sub-list:first-child').height();
                var $containermaior2 = $('.split-list .sub-list:last-child').height();
                if ($containermaior1 > $containermaior2) {
                    $(".split-list .sub-list").height($containermaior1 + 30);
                } else {
                    $(".split-list .sub-list").height($containermaior2 + 30);
                }

                $('#block-views-exp-sites-fgv-page input.form-text').keyup(function() {
                    if (this.value.length >= 3) {
                        $('#block-views-exp-sites-fgv-page .form-submit').click();
                    }
                });
                //
                // Joga o botão de 'Ver mais' dos blocos de views para o lado do block-title
                // 
                $('.block-views .block-inner').once('more').each(function() {
                    $(this).find('.more-link').insertAfter($(this).children('.block-title'));
                });

                $('.view-id-livro.view-display-id-page_1, .view-id-pesquisas_teses_pub.view-display-id-page_1').once('more').each(function() {
                    $(this).find('.more-link').insertAfter('#page-title');
                });
                $('.page-views .view-think-tank').once('more').each(function() {
                    $(this).find('.more-link').insertAfter('#page-title');
                });
            }
            /*============================================================================================================
             * SOMENTE TABLET
             =============================================================================================================*/
            // if ($(window).width() > 990 && $(window).width() < 1200) {
            //     //EVENTOS FILTRO
            //     $('.filtro-mestrado .views-exposed-form #edit-especialidade-wrapper .bef-checkboxes .form-item').addClass('swiper-slide');
            //     $('.filtro-mestrado .views-exposed-form #edit-especialidade-wrapper .bef-checkboxes').addClass('swiper-wrapper');
            //     var swiper = new Swiper('.filtro-mestrado .form-checkboxes', {
            //         pagination: '.swiper-pagination',
            //         paginationClickable: true,
            //         slidesPerView: '4'
            //     });
            // }

            /*============================================================================================================
             * SOMENTE MOBILE
             =============================================================================================================*/
            if ($window.width() < 990) {
                //                if ($(".view-display-id-page.pagina-noticia > .view-header").length > 0) {
                //                    $(".view-display-id-page.pagina-noticia > .view-header").once("processed-header").each(function () {
                //                        $("#block-views-noticias-block-4 .pagina-noticia .processed-header-processed").remove();
                //                        $(this).insertBefore(".view-display-id-page.pagina-noticia .view-filters, #block-views-noticias-block-4 .pagina-noticia .view-filters");
                //                    });
                //                }
                $('.titulo-estilo2').each(function() {
                    $(this).insertBefore($(this).parents('.view-content').prev());
                });

                //                $('.pagina-noticia #edit-area-wrapper .form-item-area > .bef-select-as-links > .form-item,.pagina-noticia #edit-area-artigo-wrapper .form-item-area > .bef-select-as-links > .form-item').once("processed-filtros").each(function () {
                //                    var select = $(document.createElement('select')).insertBefore($(this).hide().parent());
                //
                //                    $('>div a', this).each(function () {
                //                        var option = $(document.createElement('option'));
                //                        option.val($(this).parent().attr('id'))
                //                                .html($(this).html())
                //                                .appendTo(select);
                //
                //                        if ($(this).hasClass('active')) {
                //                            option.attr('selected', 'selected');
                //                        }
                //                    });
                //
                //                    select.change(function () {
                //                        console.log($('#' + $(this).val() + ' a'));
                //                        $('#' + $(this).val() + ' a').click();
                //                    });
                //                });



                //TESES
                $('.swiper-two').each(function(i, val) {
                    var iClass = 'sw-' + i;
                    $(this).addClass(iClass).find('.view-content > .item-list > ul > li').addClass('swiper-slide');
                    $(this).find('.view-content > .item-list > ul').once('swiper-two-build').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="sw-pager swiper-pagination-' + i + '"></div></div>'));
                    var swiper = new Swiper('.swiper-two.' + iClass + ' .view-content > .item-list', {
                        pagination: '.swiper-pagination-' + i,
                        paginationClickable: true,
                        slidesPerView: '2',
                        breakpoints: {
                            // when window width is <= 320px
                            480: {
                                slidesPerView: 1
                            }
                        }
                    });
                });

                // International Students. Atentar a carregar a quicktab
                $('.swiper-paragraph-one-quicktabs .paragraphs-items > .field-type-paragraphs > .field-items > .field-item').addClass('swiper-slide');
                $('.swiper-paragraph-one-quicktabs .paragraphs-items > .field-type-paragraphs > .field-items').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="swiper-pagination"></div></div>'));
                var swiperReconhecimento = new Swiper('.swiper-paragraph-one-quicktabs .paragraphs-items > .field-type-paragraphs', {
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    slidesPerView: '1'
                });

                $('.view-international-students-our-differrentials .swiper-wrapper').after($('<div class="num-pager"><div class="swiper-pagination"></div></div>'));
                var swiper = new Swiper('.view-international-students-our-differrentials .item-list', {
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    slidesPerView: '1'
                });

                // $('.paragraphs-items-field-modulos-content>.field').addClass('swiper-container');
                // $('.paragraphs-items-field-modulos-content .field .field-items').addClass('swiper-wrapper');
                // $('.paragraphs-items-field-modulos-content .field .field-items .field-item').addClass('swiper-slide');
                // var InternationalSwiper = new Swiper ('.paragraphs-items-field-modulos-content>.field', {
                //     // Optional parameters
                //     direction: 'vertical',
                //     loop: true,

                //     // If we need pagination
                //     pagination: {
                //       el: '.swiper-pagination',
                //     },

                //     // Navigation arrows
                //     navigation: {
                //       nextEl: '.swiper-button-next',
                //       prevEl: '.swiper-button-prev',
                //     },

                //     // And if we need scrollbar
                //     scrollbar: {
                //       el: '.swiper-scrollbar',
                //     },
                //   });

                //EVENTOS FILTRO
                $('.filtro-mestrado .views-exposed-form #edit-especialidade-wrapper .bef-checkboxes .form-item').addClass('swiper-slide');
                $('.filtro-mestrado .views-exposed-form #edit-especialidade-wrapper .bef-checkboxes').addClass('swiper-wrapper');
                var swiper = new Swiper('.filtro-mestrado .form-checkboxes', {
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    slidesPerView: 'auto'
                });

                //VIDEOS FILTRO
                $('.filtro-lateral.mobile-filter .views-exposed-form #edit-area-wrapper .bef-select-as-links .form-type-bef-link').once('processed').addClass('swiper-slide');
                $('.filtro-lateral.mobile-filter .views-exposed-form #edit-area-wrapper .bef-select-as-links > .form-item').once('processed').addClass('swiper-wrapper');
                var swiper = new Swiper('.filtro-lateral .views-exposed-form #edit-area-wrapper .bef-select-as-links', {
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                    slidesPerView: 'auto'
                });

                //                //TODAS AS NOTICIAS // PAGINADOR DIFERENTE
                //                $('.view-id-noticias.view-display-id-page > .view-content > .item-list > ul > li.views-row').once('processed').addClass('swiper-slide');
                //                $('.view-id-noticias.view-display-id-page > .view-content > .item-list > ul').once('processed').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="sw-pager"></div></div>'));
                //                var swiper = new Swiper('.noticias-content', {
                //                    pagination: '.sw-pager',
                //                    paginationClickable: true,
                //                    slidesPerView: '1'
                //                });

                //EVENTOS //VIDEOS // NOTICIAS GERAL // BANNER
                $('.swiper-one').once('swiper-one-build').each(function(i, val) {
                    var iClass = 'sw-' + i;
                    $(this).addClass(iClass).find('.view-content > .item-list > ul > li').addClass('swiper-slide');
                    $(this).find('.view-content > .item-list > ul').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="sw-pager swiper-pagination-' + i + '"></div></div>'));
                    var swiper = new Swiper('.swiper-one.' + iClass + ' .view-content > .item-list', {
                        pagination: '.swiper-pagination-' + i,
                        paginationClickable: true,
                        autoHeight: false,
                        slidesPerView: '1'
                    });
                });
                if ($(".swiper-one .sw-pager span").length === 1) {
                    $(".swiper-one .sw-pager").css("display", "none");
                }
                //LIVROS
                $('.swiper-four').once('swiper-one-build').each(function() {
                    $('.swiper-four .view-content > .item-list > ul > li').addClass('swiper-slide');
                    $('.swiper-four .view-content > .item-list > ul').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="livros-pagination"></div></div>'));
                    var swiper = new Swiper('.swiper-four .view-content > .item-list', {
                        pagination: '.livros-pagination',
                        paginationClickable: true,
                        slidesPerView: '2',
                        slidesPerColumn: '2'
                    });
                });
                $('#edit-destaque-wrapper .bef-select-as-checkboxes').each(function() {
                    $(this).prependTo("#edit-area-wrapper > .views-widget > .form-item > .bef-select-as-links > .form-item");
                });

                //
                // Hanz 2018-5-24
                // Relatório Anual - Swiper de Vídeos somente Mobile
                //
                $('.swiper-videos-relatorio .view-content > .list-wrapper > .list-group > .list-item').addClass('swiper-slide');
                $('.swiper-videos-relatorio .view-content > .list-wrapper > .list-group').addClass('swiper-wrapper');
                //$('.swiper-videos-relatorio .view-fotter > .videos-pagination').addClass('swiper-pagination');
                var videoRelatorio = new Swiper('.swiper-videos-relatorio .view-content > .list-wrapper', {
                    pagination: '.videos-pagination',
                    paginationClickable: true,
                    slidesPerView: '1'
                });

            }

            $(".view-videos #edit-area-wrapper .bef-select-as-links > .form-item > .form-item > a,.view-videos #edit-destaque-wrapper label").click(function() {
                $(".view-videos").attr('data-active', $(this).parent().attr('id'));
            });

            $(document).ajaxComplete(function() {
                var ida = $(".view-videos").attr('data-active');
                $('#' + ida).find("a").addClass('active');
            });

            if ($body.hasClass('page-noticias') && $body.hasClass('page-views')) {
                $(".tab-noticia .tab").click(function() {
                    if (!$(this).hasClass("active")) {
                        if ($(this).hasClass("noticias")) {
                            $(".bloco-artigos").hide();
                            $(".bloco-noticias-page").show();
                            $(".tab-noticia .tab.noticias").addClass("active").siblings().removeClass("active");
                        } else {
                            $(".bloco-artigos").show();
                            $(".bloco-noticias-page").hide();
                            $(".tab-noticia .tab.artigos").addClass("active").siblings().removeClass("active");
                        }
                    }
                });

                $(document)
                    .ajaxStart(function(event, jqxhr, settings) {
                        if ($(".tab-noticia .tab.active").hasClass("noticias")) {
                            tabativa = ".tab-noticia .tab.noticias";
                        } else {
                            tabativa = ".tab-noticia .tab.artigos";
                        }

                        console.log(tabativa);

                        if (typeof settings !== 'undefined' && settings) {
                            if (settings.type == 'POST' && (settings.url == 'https://portal.fgv.br/views/ajax') || (settings.url == 'https://des.portal.fgv.br/views/ajax')) {
                                $('.pagina-noticia').prepend('<div class="overlay-news">');
                            }
                        }

                        $(document).unbind('ajaxStart');
                    })
                    .ajaxSend(function(event, jqxhr, settings) {
                        if (typeof settings !== 'undefined' && settings) {
                            if (settings.type == 'POST' && (settings.url == 'https://portal.fgv.br/views/ajax') || (settings.url == 'https://des.portal.fgv.br/views/ajax')) {
                                $('.pagina-noticia').prepend('<div class="overlay-news">');
                            }
                        }

                        $(document).unbind('ajaxSend');
                    })
                    .ajaxStop(function(event, jqxhr, settings) {
                        $('.overlay-news').remove();
                        $(".tab-noticia .tab.active").removeClass("active");
                        $(tabativa).addClass("active").click();
                        console.log(tabativa);
                        $(document).unbind('ajaxStop');
                    });
            }

            //
            // Curso Mestrado e Doutorado
            //
            if ($body.hasClass('page-educacao-mestrado-doutorado') || $body.hasClass('page-areas') || $body.hasClass('page-educacao')) {
                var slidesShown = 0;
                var swiperLength = $('.cursos-content .swiper-slide').length;
                var cursosMD = new Swiper('.cursos-content', {
                    slidesPerView: 'auto',
                    simulateTouch: false,
                    threshold: 25,
                    slidesPerGroup: 1,
                    nextButton: '.swiper-button-next',
                    prevButton: '.swiper-button-prev',
                    onInit: function(cursosMD) {
                        slidesShown = Math.floor(cursosMD.width / 355);
                        if (swiperLength < slidesShown) {
                            $('.viewing').html(swiperLength);
                        } else {
                            $('.viewing').html(slidesShown);
                        }
                    },
                    onSlideNextStart: function(cursosMd) {
                        slidesShown++;
                        $('.viewing').html(slidesShown);
                    },
                    onSlidePrevStart: function(cursosMd) {
                        slidesShown--;
                        $('.viewing').html(slidesShown);
                    },
                    onReachBeginning: function(cursosMd) {
                        if (swiperLength < slidesShown) {
                            $('.viewing').html(swiperLength);
                        } else {
                            $('.viewing').html(slidesShown);
                        }
                    },
                    onReachEnd: function(cursosMd) {
                        $('.viewing').html(swiperLength);
                    }
                });
            }
            if ($body.hasClass('page-videos') && ($('#edit-programa-all > a').hasClass('active') || $('#edit-programa-121 > a').hasClass('active'))) {
                $('#edit-programa-122 > a').removeClass('active');
            }
        }
    };


    //
    // Hanz 2018-5-24
    //

    // Relatório Anual - Dropdown de edições anteriores

    $('.edicoes-anteriores .abre-drop').on('click', function() {
        $(this).toggleClass('active');
        $('.edicoes-anteriores > .view-content').slideToggle();
    });
    $('.edicao-atual-container .abre-drop').on('click', function(evento) {
        evento.preventDefault();
        $(this).toggleClass('active');
        $('.edicao-atual-container .dropdown').slideToggle();
    });

    $(window).on('load', function() {

        //
        // SWIPER: Relatório Anual | Galeria de Fotos
        //    

        var galeriaRelatorio = new Swiper('.galeria-relatorio-swiper-container', {
            slidesPerView: 5,
            slidesPerGroup: 5,
            slidesPerColumn: 2,
            autoplay: 5000,
            speed: 800,
            threshold: 25,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            pagination: '.swiper-pagination',
            paginationClickable: true,
            breakpoints: {
                990: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    slidesPerColumn: 2
                }
            }
        });

        /** 
         *** Relatório Anual:
         *** Sliders mobile de "Resultados das Redes" e "Prêmios"
         *** Animação nos "Resultados"
         **/
        if ($(window).width() < 990) {

            $('#block-views-relatorio-anual-block-3 .view-display-id-block_3 > .view-content > .views-row').addClass('swiper-slide');
            $('#block-views-relatorio-anual-block-3  .view-display-id-block_3 > .view-content').addClass('swiper-wrapper').after($('<div class="num-pager"><div class="redes-pagination"></div></div>'));
            var swiperMobileRedes = new Swiper('#block-views-relatorio-anual-block-3  .view-display-id-block_3', {
                slidesPerView: 1,
                slidesPerColumn: 3,
                spaceBetween: 0,
                pagination: '.redes-pagination',
                paginationClickable: true
            });

            $('#block-views-relatorio-anual-block-4 .view-display-id-block_4 > .view-content > .views-row').addClass('swiper-slide');
            $('#block-views-relatorio-anual-block-4  .view-display-id-block_4 > .view-content').addClass('swiper-wrapper')
            var swiperRelatorioPremios = new Swiper('#block-views-relatorio-anual-block-4  .view-display-id-block_4', {
                slidesPerView: 'auto',
                spaceBetween: 5
            });

        }

        var urlAtual = $(location).attr('href'),
            separator = ".",
            pathArray = urlAtual.split('/').reverse()[1];
        if (pathArray === 'en' || pathArray === 'cn') {
            separator = ',';
        };

        function commaSeparateNumber(val, separator) {
            while (/(\d+)(\d{3})/.test(val.toString())) {
                val = val.toString().replace(/(\d+)(\d{3})/, '$1' + separator + '$2');
            }
            return val;
        }

        //Posição do elemento a ser animado
        var $els = $('#block-views-relatorio-anual-block-3 .view-display-id-block_3 > .view-content');
        var $window = $(window);
        var $time = 0;
        $window.on('scroll', function() {
            $els.each(function() {

                var $this = $(this);

                if ($time === 0) {
                    if ($window.scrollTop() > ($this.offset().top - $window.height())) {
                        $time = 1;
                        $('.views-field-field-relatorio-numeros .field-content').each(function() {
                            $(this).prop('Counter', 0).animate({
                                Counter: $(this).text()
                            }, {
                                duration: 3500,
                                easing: 'swing',
                                step: function(now) {
                                    $(this).text(commaSeparateNumber(Math.ceil(now), separator));
                                }
                            });
                        });
                    }
                }

            });
        });

        /** 
         *** ## Mascaras
         **/
        //$(".form-tel, #edit-submitted-dados-dos-solicitantes-telefone").mask("(99) 9999-9999?9");
        //$(".form-tel").mask("(99) 9999-9999?9");
        //$(".form-cpf").mask("999.999.999-99");
        //$('.form-cep').mask('00000-000');

        var maskBehavior = function(val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        };
        $(".form-tel, #edit-submitted-dados-dos-solicitantes-telefone").mask(maskBehavior, {
            onKeyPress: function(val, e, field, options) {
                field.mask(maskBehavior.apply({}, arguments), options);
            }
        });
        $('.form-cpf').mask('000.000.000-00', { reverse: true });
        $('.form-cep').mask('00000-000');
        $('.form-data').mask('00/00/0000');

        /*
         * Autenticidade de Certificados e Diplomas
         * https://des.portal.fgv.br/autenticidade-certificados-e-diplomas
         */

        if ($('#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-curso').length) {

            // reset select Curso
            $('#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-curso').prop('selectedIndex', 0);

            // hide select Escola
            $(".webform-component--dados-do-certificado-diploma-a-ser-verificado--escola-instituicao").hide();

            // if a course is selected
            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-curso").change(function() {

                // get the value of Curso
                var value = $(this).find('option:selected').val();
                //console.log(value);

                if (value == '') {

                    // hide select Escola
                    $(".webform-component--dados-do-certificado-diploma-a-ser-verificado--escola-instituicao").hide();

                } else {

                    // reset select Escola
                    $('#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao').prop('selectedIndex', 0);

                    // show all values of Escola
                    $('#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao > option').each(function() {
                        $(this).show();
                    });

                    // show select Escola
                    $(".webform-component--dados-do-certificado-diploma-a-ser-verificado--escola-instituicao").show();

                    // set values of select Escola
                    switch (value) {
                        case 'tecnologo':
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EBEF_EPGE']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='DIREITO_RIO']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EMAp']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='CPDOC']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EAESP']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EESP']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='DIREITO_SP']").hide();
                            break;
                        case 'doutorado':
                            //$("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EMAp']").hide();
                            break;
                        case 'cursos_ceag':
                        case 'cursos_ceahs':
                        case "cursos_mbm":
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EBAPE']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EBEF_EPGE']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='DIREITO_RIO']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EMAp']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='CPDOC']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='EESP']").hide();
                            $("#edit-submitted-dados-do-certificado-diploma-a-ser-verificado-escola-instituicao option[value='DIREITO_SP']").hide();
                            break;
                    }
                }

            });
        }

    });

    $('#block-views-quicktabs-dci-block h2.block-title').wrap("<div class='bg-white'></div>");
    $('#block-views-quicktabs-dci-block-1 h2.block-title').wrap("<div class='bg-white'></div>");
    $('#block-views-quicktabs-dci-block-2 h2.block-title').wrap("<div class='bg-white'></div>");

    // TAHU vai ver isso depois
    // $('.swiper-wrapper').each(function() {
    //     if ($(this).children('li').length <= 10) $(this).siblings('.view-footer').hide();
    // });
    
    /*
    * Avaliações
    * https://des.portal.fgv.br/avaliacoes
    */
    
    if(window.outerWidth < 1200) { //Apenas no mobile
        $(".view-avaliaoes-pagina .views-field-nothing .titulo").each(function () {
            $(this).on('click', function (e) {
                //Abre o accordion atual
                var $acc = $(this).parent().parent();
                $acc.find('.wrapper').slideToggle(function () {
                    if (!$acc.hasClass('open')) {
                        $acc.addClass('open');
                    } else {
                        $acc.removeClass('open');
                    }
                });
            });
        });
    }
    
    //Accordion
    $(".paragraphs-item-custom-accordion").each(function () {
        $(this).on('click', function (e) {
            //Abre o accordion atual
            var $acc = $(this);
            $acc.find('.field-name-field-custom-description').slideToggle(function () {
                if (!$acc.hasClass('open')) {
                    $acc.addClass('open');
                } else {
                    $acc.removeClass('open');
                }
            });
        });
    });

})(jQuery);

function validaemail(emailAddress) {
    var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}

function getParameterByName(name, url) {
    if (!url)
        url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results)
        return null;
    if (!results[2])
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function addToCal(id) {
    jQuery.ajax({
        type: 'GET',
        url: '//' + document.domain + '/json/eventos-json/' + id,
        timeout: 45000,
        contentType: 'application/json',
        dataType: 'json',
        success: function(result) {
            result = JSON.stringify(result);
            result = JSON.parse(result);

            var calendar = createCalendar({
                options: {
                    class: 'add-to-calendar',
                    id: id
                },
                data: {
                    title: result['Items'][0].evento.title,
                    start: new Date(result['Items'][0].evento.startDate),
                    duration: 0,
                    end: new Date(result['Items'][0].evento.endDate),
                    address: result['Items'][0].evento.endereco,
                    description: result['Items'][0].evento.conteudo,
                }
            });
            jQuery('.view-eventos.view-display-id-block_7 .views-row .views-field-nothing .compartilhe-eventos').before(calendar);
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('Erro ao buscar dados do evento');
        }
    });

}
